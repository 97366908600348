<template lang="pug">
.player(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .player_info.py-5.py-sm-10
    v-container.player_info_container.d-flex
      .player.d-flex
        .player_name
          h5 {{ player.name }}
          span.text-subtitle-1.text_light_gray {{ "#" + player.jersey_number }} | {{ player.position }}
      .board_container
        .board_row.d-flex
          .board_item.text_white.bg_primary.flex-grow-1
            span {{ player.team_name }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 身高
            span(v-if="player.height") {{ " " + player.height }}CM
          .board_item.text_white.bg_primary.flex-grow-1
            span 體重
            span(v-if="player.weight") {{ " " + player.weight }}KG
        .board_row.d-flex
          .board_item.text_white.bg_primary.flex-grow-1
            span # {{ player.jersey_number }} | {{ player.position }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 生日
            span {{ " " + player.birthdate }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 年齡
            span {{ " " + player.age }}
        .board_row.d-flex
          .board_box
            span 平均得分
            span {{ player.pts }}
          .board_box
            span 平均籃板
            span {{ player.reb }}
          .board_box
            span 平均助攻
            span {{ player.ast }}
  v-container.tab_container(style="padding: 0", fluid)
    v-tabs(
      v-model="pageIndex",
      show-arrows,
      background-color="transparent",
      centered
    )
      v-tabs-slider(color="primary")
      v-tab(
        v-for="(tabItem, index) in tabList",
        :key="index",
        :ripple="false",
        @click="toPage(index)"
      ) {{ tabItem }}
  season-segment-selector(
    :leagueId="leagueId",
    :seasonSegmentId="seasonSegmentId",
    @leaf-node-clicked="leafNodeClicked"
  )
  v-main
    transition(name="page", mode="out-in")
      router-view
  custom-footer
</template>

<script>
import { getPlayer } from "@/api/league";
import CustomFooter from "@/components/common/Footer";
import SeasonSegmentSelector from "@/components/league/SeasonSegmentSelector";

export default {
  name: "LeaguePlayer",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
    SeasonSegmentSelector,
  },
  data() {
    return {
      seasonSegmentId:
        Number(this.$route.params.seasonSegmentId) > 0
          ? Number(this.$route.params.seasonSegmentId)
          : null,
      player: {},
      pageIndex: -1,
      tabList: ["比賽紀錄", "數據"],
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        default_title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    playerId() {
      return Number(this.$route.params.playerId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
    playerId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.playerId < 1)
        return;
      this.setPage(this.$route.name);
      this.toPage(this.pageIndex);
      this.getPlayerApi();
    },
    async getPlayerApi() {
      const params = {
        player_id: this.playerId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getPlayer(params);
      this.player = response.data.player;
      this.metaData.title = `${this.player.name} | 籃球數據分析Game Changer`;
    },
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
    },
    setPage(name) {
      switch (name) {
        case "LeaguePlayerGameLog":
          this.pageIndex = 0;
          break;
        case "LeaguePlayerStatistics":
          this.pageIndex = 1;
          break;
      }
    },
    toPage(index) {
      this.pageIndex = index;
      switch (index) {
        case 0:
          this.$router.push({
            name: "LeaguePlayerGameLog",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              playerId: this.playerId,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "LeaguePlayerStatistics",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              playerId: this.playerId,
            },
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/league/player";
</style>
